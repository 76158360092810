import React from "react";
import Layout from "../layout";
import Seo from "../components/Seo/Seo";
import StripHTML from "../utils/StripHTML";
import config from "../../data/SiteConfig";
import GImage from "../components/Picture/GImage";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import PdfViewer from "../components/PdfViewer/PdfViewer";
import { graphql } from "gatsby";

const PracticeTemplate = (props) => {
  const { directus } = props.data;

  return (
    <Layout>
      <div className="h-20 md:h-28"></div>
      {/* {directus.settings &&
        directus.settings.pratiques_illustration !== null && (
          <GImage
            className="h-44 md:h-72 bg-practices bg-cover"
            src={directus.settings.pratiques_illustration.imageFile}
            alt="Image de la page d'une pratique du secteur langues du Groupe Français d'éducation Nouvelle"
          />
        )} */}
      <div className="practices-container min-h-80vh">
        <Seo
          title={`${directus.practices_by_id.title}`}
          description={`${directus.practices_by_id.title} : ${
            directus.practices_by_id.intro &&
            directus.practices_by_id.intro.length > 1
              ? StripHTML(directus.practices_by_id.intro)
              : directus.practices_by_id.description &&
                directus.practices_by_id.description.length > 1
              ? StripHTML(directus.practices_by_id.description)
              : "pratique du " + config.siteTitleShort
          }`}
          article={true}
        />
        <Breadcrumb location={"pratiques"} />
        {directus.practices_by_id && (
          <div className="practices max-w-6xl mx-auto px-3 sm:px-6 pt-8 pb-20 flex items-center">
            <div className="mx-auto w-auto">
              <h1 className="text-center mt-6 mb-8">
                {directus.practices_by_id.title}
              </h1>
              <hr className="bg-red-500 border-none h-2" />
              {directus.practices_by_id.intro && (
                <div className="text-center max-w-3xl mx-auto mt-6 mb-12">
                  <p className="text-lg text-gray-600">
                    {directus.practices_by_id.intro}
                  </p>
                </div>
              )}
              <div className="w-full relative">
                {directus.practices_by_id.illustration && (
                  <div className="w-full float-none lg:float-left lg:w-1/2 pr-0 pb-6 lg:pr-6">
                    <GImage
                      objectFit="contain"
                      imgClassName="h-auto w-full max-h-128 min-w-0 min-h-0"
                      className="h-auto w-full max-h-128 min-w-0 min-h-0"
                      src={directus.practices_by_id.illustration.imageFile}
                      alt="Illustration de la pratique du secteur langues du Groupe Français d'éducation Nouvelle"
                    />
                    {directus.practices_by_id.illustration.description && (
                      <p className="text-sm italic text-gray-600 mt-2 text-center">
                        {directus.practices_by_id.illustration.description}
                      </p>
                    )}
                  </div>
                )}
                {directus.practices_by_id.intro && (
                  <div
                    className="mt-6 text-editor"
                    dangerouslySetInnerHTML={{
                      __html: `${directus.practices_by_id.intro}`,
                    }}
                  />
                )}
                {directus.practices_by_id.description && (
                  <div
                    className="mt-6 text-editor"
                    dangerouslySetInnerHTML={{
                      __html: `${directus.practices_by_id.description}`,
                    }}
                  />
                )}
                {directus.practices_by_id.documents.length > 0 && (
                  <div className="w-full clear-both">
                    <h4 className="flex items-center mt-10 mb-1">
                      <span className={`material-icons-outlined pr-2 py-0`}>
                        description
                      </span>
                      Document
                      {directus.practices_by_id.documents.length > 1 && "s"} :
                    </h4>
                    <ul>
                      {directus.practices_by_id.documents.map((document) => {
                        return (
                          document &&
                          document.directus_files_id !== null && (
                            <li
                              className={`my-2 px-3 bg-white mb-3 shadow-md ${
                                document.directus_files_id.description
                                  ? "py-2"
                                  : "py-2"
                              }`}
                              key={document.directus_files_id.id}
                            >
                              <div className="inline-flex items-center">
                                <a
                                  title="Télécharger le document"
                                  className={`inline-flex items-center hover:bg-gray-100 px-2 py-2`}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                  href={
                                    document.directus_files_id.imageFile
                                      .publicURL
                                  }
                                >
                                  <span className={`material-icons-outlined`}>
                                    file_download
                                  </span>
                                </a>
                                {document.directus_files_id.imageFile
                                  .publicURL && (
                                  <PdfViewer
                                    title={document.directus_files_id.title}
                                    extension={
                                      document.directus_files_id.imageFile
                                        .extension
                                    }
                                    pdfFile={
                                      document.directus_files_id.imageFile
                                        .publicURL
                                    }
                                  />
                                )}
                                <span className="ml-2 font-normal text-base">
                                  {document.directus_files_id.title}
                                </span>
                              </div>

                              {document.directus_files_id.description && (
                                <p className="pb-2 px-2">
                                  <i className="text-gray-600 text-sm">
                                    {document.directus_files_id.description}
                                  </i>
                                </p>
                              )}
                            </li>
                          )
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};
export default PracticeTemplate;

export const query = graphql`
  query ($id: ID!) {
    directus: allDirectus {
      # settings {
      #   id
      #   pratiques_illustration {
      #     id
      #     imageFile {
      #       id
      #       childImageSharp {
      #         id
      #         gatsbyImageData(
      #           quality: 80
      #           layout: FULL_WIDTH
      #           placeholder: TRACED_SVG
      #           tracedSVGOptions: { color: "#d8b0b1" }
      #         )
      #       }
      #     }
      #   }
      # }
      practices_by_id(id: $id) {
        id
        title
        intro
        description
        illustration {
          id
          description
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(
                quality: 80
                layout: CONSTRAINED
                placeholder: TRACED_SVG
                tracedSVGOptions: { color: "#d8b0b1" }
              )
            }
          }
        }
        documents {
          directus_files_id {
            id
            title
            filename_disk
            filename_download
            description
            imageFile {
              id
              extension
              publicURL
            }
          }
        }
      }
    }
  }
`;
